.mosaic.mosaic-theme-ett {
  background: var(--palette-background-default);
  border-top-left-radius: 4px;
  --highlight-color: var(--palette-primary-main);
  --highlight-color-opacity: var(--palette-primary-opacity100);
  --mosaic-variant-yellow: var(--palette-historical-border);
}

.mosaic.mosaic-theme-ett .mosaic-tile {
  margin: var(--spacing);
  border-radius: var(--borderRadius);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-body,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-body {
  border-radius: var(--borderRadius);
  background: var(--palette-background-dark);
  font-family: var(--ettFontFamily);
}

.mosaic.mosaic-theme-ett .mosaic-window-variant--yellow:has( > .mosaic-window-body) {
  border-color: var(--moasic-variant-yellow);
}

.mosaic.mosaic-theme-ett.mosaic-zero-state {
  background: pink;
  border-radius: var(--borderRadius);
}

.mosaic.mosaic-theme-ett.mosaic-zero-state .default-zero-state-icon {
  font-size: 120px;
}

.mosaic.mosaic-theme-ett .mosaic-split:hover {
  background: none;
}

.mosaic.mosaic-theme-ett .mosaic-split:hover .mosaic-split-line {
  box-shadow: 0 0 0 1px var(--highlight-color);
}

.mosaic.mosaic-blueprint-theme.mosaic-drop-target .drop-target-container .drop-target,
.mosaic.mosaic-theme-ett.mosaic-drop-target .drop-target-container .drop-target {
  background: var(--highlight-color-opacity);
  border: 2px solid var(--highlight-color);
  transition: opacity 100ms;
  border-radius: var(--borderRadius);
}

.mosaic.mosaic-theme-ett .mosaic-window,
.mosaic.mosaic-theme-ett .mosaic-preview {
  border-radius: var(--borderRadius);
  box-shadow: none;
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar {
  border-top-right-radius: var(--borderRadius);
  border-top-left-radius: var(--borderRadius);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar.draggable:hover,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar.draggable:hover {
  background: linear-gradient(to bottom,var(--palette-background-paper),var(--palette-background-paperAlt));
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-toolbar.draggable:hover .mosaic-window-title,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-toolbar.draggable:hover .mosaic-window-title {
  color: var(--color-text-primary);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-title,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-title {
  font-weight: 600;
  color: var(--color-text-primary);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-controls .-button:before,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-controls .-button,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-controls .-button:before {
  color: var(--palette-primary-main);
}

.mosaic.mosaic-theme-ett .mosaic-window .default-preview-icon,
.mosaic.mosaic-theme-ett .mosaic-preview .default-preview-icon {
  font-size: 72px;
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar {
  transition: height 250ms;
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar .-button,
.mosaic.mosaic-theme-ett .mosaic-window .mosaic-window-additional-actions-bar .-button:before,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar .-button,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-window-additional-actions-bar .-button:before {
  color: var(--palette-primary-main);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-preview,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-preview {
  border: 1px solid var(--palette-background-dark);
}

.mosaic.mosaic-theme-ett .mosaic-window .mosaic-preview h4,
.mosaic.mosaic-theme-ett .mosaic-preview .mosaic-preview h4 {
  color: var(--color-text-primary);
}


