
/* MUI V4 does not recognize @starting-style, and throws them out. Use regular css instead. */

@starting-style {
  .enteringPanel.forward {
    transform: translateX(100%);
  }
  .enteringPanel.backward {
    transform: translateX(-100%);
  }
}


